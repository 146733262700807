define('ember-math-helpers/helpers/acosh', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.acosh = acosh;
  var Helper = Ember.Helper;
  function acosh(params) {
    return Math.acosh(params[0]);
  }

  exports.default = Helper.helper(acosh);
});