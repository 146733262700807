define('ember-math-helpers/helpers/expm1', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.expm1 = expm1;
  var Helper = Ember.Helper;
  function expm1(params) {
    return Math.expm1(params[0]);
  }

  exports.default = Helper.helper(expm1);
});