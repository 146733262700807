define('ember-cli-stripe/components/stripe-checkout', ['exports', 'ember', 'ember-cli-stripe/templates/components/stripe-checkout'], function (exports, _ember, _emberCliStripeTemplatesComponentsStripeCheckout) {
  var bool = _ember['default'].computed.bool;
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;

  /**
   * Stripe checkout component for accepting payments with
   * an embedded form.
   *
   * Stripe docs: https://stripe.com/docs/tutorials/checkout
   * List of possible Stripe options: https://stripe.com/docs/checkout#integration-simple-options
   *
   * Usage:
   * {{stripe-checkout
   *   description=billingPlan.description
   *   amount=billingPlan.amount
   * }}
   *
   */
  exports['default'] = Component.extend({
    classNames: ['stripe-checkout'],
    attributeBindings: ['isDisabled:disabled'],
    tagName: 'button',
    layout: _emberCliStripeTemplatesComponentsStripeCheckout['default'],

    stripe: service(),

    /**
     * Stripe checkout button text.
     */
    label: 'Pay with card',

    /**
     * When true, the Stripe checkout button is disabled.
     */
    isDisabled: false,

    /**
     * Controls opening the Stripe Checkout modal dynamically.
     * Will open the mcheckout modal when true.
     */
    showCheckout: false,

    hasBlock: bool('template').readOnly(),

    /**
     * Kick up the modal if we're clicked.
     */
    click: function click(e) {
      e.preventDefault();
      this.openCheckout();
    },

    /**
     * Opens the Stripe modal for payment.
     */
    openCheckout: function openCheckout() {
      this.get('stripe').open(this);
    },

    closeCheckout: function closeCheckout() {
      this.get('stripe').close(this);
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.get('stripe').registerComponent(this);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('showCheckout')) {
        this.openCheckout();
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.closeCheckout();
      this.get('stripe').unregisterComponent(this);
    }
  });
});