define('ember-power-select-with-create/components/power-select-with-create', ['exports', 'ember', 'ember-power-select-with-create/templates/components/power-select-with-create', 'ember-power-select/utils/group-utils'], function (exports, _ember, _emberPowerSelectWithCreateTemplatesComponentsPowerSelectWithCreate, _emberPowerSelectUtilsGroupUtils) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    layout: _emberPowerSelectWithCreateTemplatesComponentsPowerSelectWithCreate['default'],
    matcher: _emberPowerSelectUtilsGroupUtils.defaultMatcher,

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].assert('{{power-select-with-create}} requires an `oncreate` function', this.get('oncreate') && typeof this.get('oncreate') === 'function');
    },

    // CPs
    optionsArray: computed('options.[]', function () {
      var options = this.get('options');
      if (!options) {
        return _ember['default'].A();
      }
      if (options.then) {
        return options.then(function (value) {
          return _ember['default'].A(value).toArray();
        });
      } else {
        return _ember['default'].A(options).toArray();
      }
    }),

    powerSelectComponentName: computed('multiple', function () {
      return this.get('multiple') ? 'power-select-multiple' : 'power-select';
    }),

    shouldShowCreateOption: function shouldShowCreateOption(term, options) {
      return this.get('showCreateWhen') ? this.get('showCreateWhen')(term, options) : true;
    },

    addCreateOption: function addCreateOption(term, results) {
      if (this.shouldShowCreateOption(term, results)) {
        if (this.get('showCreatePosition') === 'bottom') {
          results.push(this.buildSuggestionForTerm(term));
        } else {
          results.unshift(this.buildSuggestionForTerm(term));
        }
      }
    },
    // Actions
    actions: {
      searchAndSuggest: function searchAndSuggest(term, select) {
        var _this = this;

        return RSVP.resolve(this.get('optionsArray')).then(function (newOptions) {

          if (term.length === 0) {
            return newOptions;
          }

          var searchAction = _this.get('search');
          if (searchAction) {
            return _ember['default'].RSVP.resolve(searchAction(term, select)).then(function (results) {
              if (results.toArray) {
                results = results.toArray();
              }
              _this.addCreateOption(term, results);
              return results;
            });
          }

          newOptions = _this.filter(_ember['default'].A(newOptions), term);
          _this.addCreateOption(term, newOptions);

          return newOptions;
        });
      },

      selectOrCreate: function selectOrCreate(selection, select) {
        var suggestion = undefined;
        if (this.get('multiple')) {
          suggestion = selection.filter(function (option) {
            return option.__isSuggestion__;
          })[0];
        } else if (selection && selection.__isSuggestion__) {
          suggestion = selection;
        }

        if (suggestion) {
          this.get('oncreate')(suggestion.__value__, select);
        } else {
          this.get('onchange')(selection, select);
        }
      }
    },

    // Methods
    filter: function filter(options, searchText) {
      var _this2 = this;

      var matcher = undefined;
      if (this.get('searchField')) {
        matcher = function (option, text) {
          return _this2.matcher(get(option, _this2.get('searchField')), text);
        };
      } else {
        matcher = function (option, text) {
          return _this2.matcher(option, text);
        };
      }
      return (0, _emberPowerSelectUtilsGroupUtils.filterOptions)(options || [], searchText, matcher);
    },

    buildSuggestionForTerm: function buildSuggestionForTerm(term) {
      return {
        __isSuggestion__: true,
        __value__: term,
        text: this.buildSuggestionLabel(term)
      };
    },

    buildSuggestionLabel: function buildSuggestionLabel(term) {
      var buildSuggestion = this.get('buildSuggestion');
      if (buildSuggestion) {
        return buildSuggestion(term);
      }
      return 'Add "' + term + '"...';
    }
  });
});