define('ember-math-helpers/helpers/atan2', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.atan2 = atan2;
  var Helper = Ember.Helper;
  function atan2(params) {
    return Math.atan2(params[0], params[1]);
  }

  exports.default = Helper.helper(atan2);
});