define('ember-math-helpers/helpers/sin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sin = sin;
  var Helper = Ember.Helper;
  function sin(params) {
    return Math.sin(params[0]);
  }

  exports.default = Helper.helper(sin);
});