define('ember-math-helpers/helpers/hypot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hypot = hypot;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Helper = Ember.Helper;
  function hypot(params) {
    return Math.hypot.apply(Math, _toConsumableArray(params));
  }

  exports.default = Helper.helper(hypot);
});