define('ember-math-helpers/helpers/round', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.round = round;
  var Helper = Ember.Helper;
  function round(number) {
    return Math.round(number[0]);
  }

  exports.default = Helper.helper(round);
});