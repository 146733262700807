define('ember-math-helpers/helpers/trunc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.trunc = trunc;
  var Helper = Ember.Helper;
  function trunc(params) {
    return Math.trunc(params[0]);
  }

  exports.default = Helper.helper(trunc);
});