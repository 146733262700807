define(
    "d3-request",
    ["exports", "d3-collection", "d3-dispatch", "d3-dsv"],
    function (exports,d3Collection,d3Dispatch,d3Dsv) { 'use strict';

    var request = function(url, callback) {
      var request,
          event = d3Dispatch.dispatch("beforesend", "progress", "load", "error"),
          mimeType,
          headers = d3Collection.map(),
          xhr = new XMLHttpRequest,
          user = null,
          password = null,
          response,
          responseType,
          timeout = 0;

      // If IE does not support CORS, use XDomainRequest.
      if (typeof XDomainRequest !== "undefined"
          && !("withCredentials" in xhr)
          && /^(http(s)?:)?\/\//.test(url)) xhr = new XDomainRequest;

      "onload" in xhr
          ? xhr.onload = xhr.onerror = xhr.ontimeout = respond
          : xhr.onreadystatechange = function(o) { xhr.readyState > 3 && respond(o); };

      function respond(o) {
        var status = xhr.status, result;
        if (!status && hasResponse(xhr)
            || status >= 200 && status < 300
            || status === 304) {
          if (response) {
            try {
              result = response.call(request, xhr);
            } catch (e) {
              event.call("error", request, e);
              return;
            }
          } else {
            result = xhr;
          }
          event.call("load", request, result);
        } else {
          event.call("error", request, o);
        }
      }

      xhr.onprogress = function(e) {
        event.call("progress", request, e);
      };

      request = {
        header: function(name, value) {
          name = (name + "").toLowerCase();
          if (arguments.length < 2) return headers.get(name);
          if (value == null) headers.remove(name);
          else headers.set(name, value + "");
          return request;
        },

        // If mimeType is non-null and no Accept header is set, a default is used.
        mimeType: function(value) {
          if (!arguments.length) return mimeType;
          mimeType = value == null ? null : value + "";
          return request;
        },

        // Specifies what type the response value should take;
        // for instance, arraybuffer, blob, document, or text.
        responseType: function(value) {
          if (!arguments.length) return responseType;
          responseType = value;
          return request;
        },

        timeout: function(value) {
          if (!arguments.length) return timeout;
          timeout = +value;
          return request;
        },

        user: function(value) {
          return arguments.length < 1 ? user : (user = value == null ? null : value + "", request);
        },

        password: function(value) {
          return arguments.length < 1 ? password : (password = value == null ? null : value + "", request);
        },

        // Specify how to convert the response content to a specific type;
        // changes the callback value on "load" events.
        response: function(value) {
          response = value;
          return request;
        },

        // Alias for send("GET", …).
        get: function(data, callback) {
          return request.send("GET", data, callback);
        },

        // Alias for send("POST", …).
        post: function(data, callback) {
          return request.send("POST", data, callback);
        },

        // If callback is non-null, it will be used for error and load events.
        send: function(method, data, callback) {
          xhr.open(method, url, true, user, password);
          if (mimeType != null && !headers.has("accept")) headers.set("accept", mimeType + ",*/*");
          if (xhr.setRequestHeader) headers.each(function(value, name) { xhr.setRequestHeader(name, value); });
          if (mimeType != null && xhr.overrideMimeType) xhr.overrideMimeType(mimeType);
          if (responseType != null) xhr.responseType = responseType;
          if (timeout > 0) xhr.timeout = timeout;
          if (callback == null && typeof data === "function") callback = data, data = null;
          if (callback != null && callback.length === 1) callback = fixCallback(callback);
          if (callback != null) request.on("error", callback).on("load", function(xhr) { callback(null, xhr); });
          event.call("beforesend", request, xhr);
          xhr.send(data == null ? null : data);
          return request;
        },

        abort: function() {
          xhr.abort();
          return request;
        },

        on: function() {
          var value = event.on.apply(event, arguments);
          return value === event ? request : value;
        }
      };

      if (callback != null) {
        if (typeof callback !== "function") throw new Error("invalid callback: " + callback);
        return request.get(callback);
      }

      return request;
    };

    function fixCallback(callback) {
      return function(error, xhr) {
        callback(error == null ? xhr : null);
      };
    }

    function hasResponse(xhr) {
      var type = xhr.responseType;
      return type && type !== "text"
          ? xhr.response // null on error
          : xhr.responseText; // "" on error
    }

    var type = function(defaultMimeType, response) {
      return function(url, callback) {
        var r = request(url).mimeType(defaultMimeType).response(response);
        if (callback != null) {
          if (typeof callback !== "function") throw new Error("invalid callback: " + callback);
          return r.get(callback);
        }
        return r;
      };
    };

    var html = type("text/html", function(xhr) {
      return document.createRange().createContextualFragment(xhr.responseText);
    });

    var json = type("application/json", function(xhr) {
      return JSON.parse(xhr.responseText);
    });

    var text = type("text/plain", function(xhr) {
      return xhr.responseText;
    });

    var xml = type("application/xml", function(xhr) {
      var xml = xhr.responseXML;
      if (!xml) throw new Error("parse error");
      return xml;
    });

    var dsv = function(defaultMimeType, parse) {
      return function(url, row, callback) {
        if (arguments.length < 3) callback = row, row = null;
        var r = request(url).mimeType(defaultMimeType);
        r.row = function(_) { return arguments.length ? r.response(responseOf(parse, row = _)) : row; };
        r.row(row);
        return callback ? r.get(callback) : r;
      };
    };

    function responseOf(parse, row) {
      return function(request$$1) {
        return parse(request$$1.responseText, row);
      };
    }

    var csv = dsv("text/csv", d3Dsv.csvParse);

    var tsv = dsv("text/tab-separated-values", d3Dsv.tsvParse);

    exports.request = request;
    exports.html = html;
    exports.json = json;
    exports.text = text;
    exports.xml = xml;
    exports.csv = csv;
    exports.tsv = tsv;

    Object.defineProperty(exports, '__esModule', { value: true });

    }
);