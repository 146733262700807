define('ember-math-helpers/helpers/clz32', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.clz32 = clz32;
  var Helper = Ember.Helper;
  function clz32(params) {
    return Math.clz32(params[0]);
  }

  exports.default = Helper.helper(clz32);
});