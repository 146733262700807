define('ember-math-helpers/helpers/abs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.abs = abs;
  var Helper = Ember.Helper;
  function abs(params) {
    return Math.abs(params[0]);
  }

  exports.default = Helper.helper(abs);
});