define('ember-math-helpers/helpers/tanh', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tanh = tanh;
  var Helper = Ember.Helper;
  function tanh(params) {
    return Math.tanh(params[0]);
  }

  exports.default = Helper.helper(tanh);
});