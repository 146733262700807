define('ember-semantic-ui-calendar/components/ui-calendar', ['exports', 'ember', 'semantic-ui-ember/mixins/base', 'ember-semantic-ui-calendar/templates/components/ui-calendar'], function (exports, _ember, _semanticUiEmberMixinsBase, _emberSemanticUiCalendarTemplatesComponentsUiCalendar) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;

  /**
   * @class UiCalendar
   * @extends Ember.Component
   * @namespace Semantic
   * @see https://github.com/mdehoog/Semantic-UI-Calendar
   */
  exports['default'] = Component.extend(_semanticUiEmberMixinsBase['default'], {
    layout: _emberSemanticUiCalendarTemplatesComponentsUiCalendar['default'],

    module: 'calendar',
    classNames: ['ui', 'calendar'],
    ignorableAttrs: ['date', 'icon', 'placeholder'],

    /**
     * Name of an icon to display in the input. You can pass `false` to not show an icon.
     *
     * @property icon
     * @type String|Boolean
     * @default 'calendar'
     * @public
     * @see http://semantic-ui.com/elements/icon.html
     */
    icon: 'calendar',

    /**
     * Placeholder for the input.
     *
     * @property placeholder
     * @type String
     * @default ''
     * @public
     */
    placeholder: '',

    /**
     * If the user can clear the value using a clear button inside the input.
     *
     * @property allowClear
     * @type Boolean
     * @default false
     * @public
     */
    allowClear: false,

    /**
     * Name of the icon to use as a button the clear the input value.
     *
     * @property clearIcon
     * @type String
     * @default 'clear'
     * @public
     */
    clearIcon: 'remove',

    /**
     * @property showClearButton
     * @type Boolean
     * @private
     */
    showClearButton: computed('date', 'allowClear', function () {
      var _getProperties = this.getProperties('date', 'allowClear');

      var date = _getProperties.date;
      var allowClear = _getProperties.allowClear;

      var showClearButton = allowClear && !isEmpty(date);
      return showClearButton;
    }),

    /**
     * Class names used in .ui.input element to configure icon visibility.
     *
     * @property inputIconsClassNames
     * @type String
     * @private
     */
    inputIconsClassNames: computed('icon', 'showClearButton', function () {
      var _getProperties2 = this.getProperties('icon', 'showClearButton');

      var icon = _getProperties2.icon;
      var showClearButton = _getProperties2.showClearButton;

      var hasLeftIcon = !isEmpty(icon) && icon !== false;
      var classNames = [];

      if (hasLeftIcon) {
        classNames.push('left');
      }

      if (showClearButton) {
        classNames.push('right');
      }

      if (hasLeftIcon || showClearButton) {
        classNames.push('icon');
      }

      return classNames.join(' ');
    }),

    /**
     * Action executed when the user presses the `enter` key when the input is focused.
     *
     * @event onEnter
     * @param {Event} event
     */
    onEnter: null,

    /**
     * Action executed when the user presses the `escape` key when the input is focused.
     *
     * @event onEscape
     * @param {Event} event
     */
    onEscape: null,

    /**
     * Action executed when the user presses the any key when the input is focused.
     *
     * @event onKeyDown
     * @param {Event} event
     */
    onKeyDown: null,

    /**
     * Action executed when the date input is focused.
     *
     * @event onFocusOut
     */
    onFocusIn: null,

    /**
     * Action executed when the date input looses focus.
     *
     * @event onFocusOut
     */
    onFocusOut: null,

    didInitSemantic: function didInitSemantic() {
      this._super.apply(this, arguments);

      var date = this.get('date');
      if (date) {
        this.$().calendar('set date', date);
      }
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.execute('set date', this.get('date'), true, false);
    },

    actions: {
      onInputKeyDown: function onInputKeyDown(value, event) {
        this.sendAction('onKeyDown', event);

        switch (event.keyCode) {
          case 13:
            this.sendAction('onEnter', event);
            break;
          case 27:
            this.sendAction('onEscape', event);
            break;
        }
      }
    }
  });
});