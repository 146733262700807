define('ember-toggle/components/x-toggle-switch/component', ['exports', 'ember-toggle/components/x-toggle-switch/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _template.default,
    tagName: 'span',
    classNames: ['x-toggle-container'],
    classNameBindings: ['size', 'disabled:x-toggle-container-disabled', 'value:x-toggle-container-checked'],

    themeClass: computed('theme', function () {
      return 'x-toggle-' + (this.get('theme') || 'default');
    })
  });
});